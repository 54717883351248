import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes as appRoutes } from './routes';
import Layout from './components/Layouts';
import {
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#63b8ff",
        main: "#0989e3",
        dark: "#005db0",
        contrastText: "#000",
      },
      secondary: {
        main: "#4db6ac",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#000",
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
            <Routes>
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                >
                  {route.param && (
                    <Route
                      key={`${route.key}_${route.param}`}
                      path={`:${route.param}`}
                      element={<route.component />}
                    />
                  )}
                </Route>
              ))}
            </Routes>
          </Layout>
      </Router>
    </ThemeProvider>
  );


  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <p>Message: {message}</p>
  //       <button onClick={() => fetch('/.netlify/functions/hello')
  //         .then(res => res.json())
  //         .then(({ msg }) => setMessage(msg))}>
  //         Klikk meg
  //       </button>
  //     </header>
  //   </div>
  // );
}

export default App;
