import React from "react";
import { useParams } from "react-router-dom";
import { Box, FormControl, TextField, Typography } from "@mui/material";

const FASTLEDD = 325;
const TERSKELPRIS = 70;
const KOMPENSASJONSGRAD = 90;

const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
];

const Electricity = () => {
  const { monthId } = useParams();
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Typography variant="h3">Strømkalkulator {months[monthId-1]}</Typography>

        <FormControl variant="standard" fullWidth sx={{
            gap: 2
        }}>
            <TextField id="standard-basic" label="Forbruk (kWt)" variant="standard" />
            <TextField id="standard-basic" label="Kostnad (kr)" variant="standard" />
            <TextField id="standard-basic" label="Gjennomsnittlig spotpris (øre)" variant="standard" />
            <TextField id="standard-basic" label="Fastledd (kr)" variant="standard" value={FASTLEDD} />
            <TextField id="standard-basic" label="Terskelpris (øre)" variant="standard" value={TERSKELPRIS} disabled />
            <TextField id="standard-basic" label="Kompensasjonsgrad (%)" variant="standard" value={KOMPENSASJONSGRAD} />
        </FormControl>
      </div>
    </Box>
  );
};

export default Electricity;